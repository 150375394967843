import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { ThemeProvider as TaniumThemeProvider } from '@tanium/react-theme-context';
import { bluestar } from '@tanium/theme-data';
import { History } from 'history';

interface Props {
  history: History;
}

const AppWrapper: React.FC<Props> = ({ history, children }) => {
  return (
    <EmotionThemeProvider theme={bluestar}>
      <TaniumThemeProvider theme={bluestar}>
        <Router history={history}>{children}</Router>
      </TaniumThemeProvider>
    </EmotionThemeProvider>
  );
};

export default AppWrapper;
