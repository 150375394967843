import React from 'react';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { NotificationType } from '@tanium/react-notifications-base';
import { Notification as TaniumNotification } from '@tanium/react-persistent-notifications';
import AppHeaderStyle from '../AppHeader/AppHeaderStyle';
import TopContentStyle from '../TopContent/TopContentStyle';

const headerHeight =
  AppHeaderStyle.borderHeight +
  AppHeaderStyle.containerHeight +
  TopContentStyle.containerHeight +
  TopContentStyle.dividerHeight;

const NotificationWrapper = styled('div')(
  css({
    width: '30%',
    margin: 'auto',
    marginTop: `${headerHeight - 10}px`,
    position: 'absolute',
    overflow: 'hidden',
    top: '0',
    left: '0',
    right: '0',
    zIndex: 101,
  })
);

interface Props {
  type: NotificationType;
  role: string;
  message: string;
  onDismiss: () => void;
}

const Notification: React.VFC<Props> = ({ type, role, message, onDismiss }) => {
  if (!message || message === '') {
    return null;
  }

  return (
    <NotificationWrapper>
      <TaniumNotification type={type} role={role} onDismiss={onDismiss}>
        {message}
      </TaniumNotification>
    </NotificationWrapper>
  );
};

export default Notification;
