import React from 'react';
import MdmIcon from '../../static/mdm_icon.svg';

const DEFAULT_LOGO_HEIGHT = 40;

const MdmLogo: React.VFC = () => {
  return <img src={MdmIcon} alt="MDM Icon" height={DEFAULT_LOGO_HEIGHT} />;
};

export default MdmLogo;
