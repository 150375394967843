import React from 'react';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { ThemeColors, ThemeSpace } from '@tanium/theme-data';
import Logo from './Logo';
import CurrentUser from './CurrentUser';
import SignOut from './SignOut';
import AppHeaderStyle from './AppHeaderStyle';

const Container = styled(Box)(
  css({
    color: ThemeColors.Active2,
    bg: ThemeColors.HeaderTier2,
    height: `${AppHeaderStyle.containerHeight}px`,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
  })
);

interface Props {
  username: string | undefined;
}

const AppHeader: React.VFC<Props> = ({ username }) => {
  return (
    <Box position="fixed" top={0} width="100%" zIndex={99}>
      <Box bg={ThemeColors.BrandRed} height={`${AppHeaderStyle.borderHeight}px`} width="100%" />
      <Container>
        <Box mx={3}>
          <Logo />
        </Box>
        <Box mx={3} flexGrow={1} />
        {username ? (
          <>
            <Box mx={3} className="hideOnSmallScreen">
              <CurrentUser username={username} />
            </Box>
            <Box ml={ThemeSpace.minor[1]} mr={ThemeSpace.minor[1]} className="hideOnSmallScreen">
              |
            </Box>
            <Box mx={3}>
              <SignOut />
            </Box>
          </>
        ) : null}
      </Container>
    </Box>
  );
};

export default AppHeader;
