import React from 'react';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(
  css({
    display: 'flex',
    flexDirection: 'column',
  })
);

const ProductName = styled('span')(
  css({
    height: '24px',
    color: '#292D38',
    fontSize: '18px',
    fontWeight: 'bold',
  })
);

const ProductDescription = styled('span')(
  css({
    height: '21px',
    color: '#646A7E',
    fontSize: '16px',
    fontWeight: 'normal',
  })
);

const ProductInfo: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Container mx={2}>
      <ProductName>{t('MDM')}</ProductName>
      <ProductDescription>{t('ENROLL_DEVICE')}</ProductDescription>
    </Container>
  );
};

export default ProductInfo;
