import React from 'react';
import { InstallTanium } from '@tanium/react-graphics';

const DEFAULT_LOGO_HEIGHT = 80;

const EnrollIcon: React.VFC = () => {
  return <InstallTanium height={DEFAULT_LOGO_HEIGHT} />;
};

export default EnrollIcon;
