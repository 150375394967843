import React, { useCallback } from 'react';
import { Button } from '@tanium/react-button';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { useTranslation } from 'react-i18next';

const LoggedOutDescription = styled('span')(
  css({
    marginTop: '30px',
    fontSize: '25px',
    fontWeight: 'bold',
    color: '#292D38',
    textAlign: 'center',
  })
);

interface Props {
  setUserClickedLogOut: (userHasClickedLogOut: boolean) => void;
}

const SubmitButton = styled(Button)(
  css({
    display: 'flex',
    alignItems: 'center',
    maxWidth: '300px',
    alignSelf: 'center',
    marginTop: '30px',
  })
);
const SignedOut: React.VFC<Props> = ({ setUserClickedLogOut }) => {
  const { t } = useTranslation();
  const handleSubmit = useCallback(() => {
    setUserClickedLogOut(false); //  forces page redraw
    localStorage.setItem('userClickedLogOut', 'false'); // clears state
  }, [setUserClickedLogOut]);

  return (
    <>
      <LoggedOutDescription>{t('SIGNED_OUT')}</LoggedOutDescription>
      <SubmitButton onClick={handleSubmit}>
        {<label style={{ margin: 'auto', cursor: 'pointer' }}>{t('RETURN_TO_SIGNIN')}</label>}
      </SubmitButton>
    </>
  );
};

export default SignedOut;
