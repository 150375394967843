import React, { useCallback } from 'react';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { ThemeColors } from '@tanium/theme-data';
import { signOutSession } from '../../utils/Auth';
import { useTranslation } from 'react-i18next';

const SmallLink = styled('a')(
  css({
    fontSize: 12,
    ':hover': {
      color: ThemeColors.HeaderLinkHover,
      cursor: 'pointer',
    },
  })
);

const SignOut: React.VFC = () => {
  const signOut = useCallback(() => {
    void (async () => {
      localStorage.setItem('userClickedLogOut', 'true');
      await signOutSession();
    })();
  }, []);
  const { t } = useTranslation();

  return <SmallLink onClick={signOut}>{t('SIGN_OUT')}</SmallLink>;
};

export default SignOut;
