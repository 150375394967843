import React from 'react';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { ThemeColors, ThemeSpace } from '@tanium/theme-data';
import TopContentStyle from './TopContentStyle';
import AppHeaderStyle from '../AppHeader/AppHeaderStyle';
import MdmLogo from './MdmLogo';
import TaniumTM from './TaniumTM';
import ProductInfo from './ProductInfo';

const headerHeight = AppHeaderStyle.borderHeight + AppHeaderStyle.containerHeight;

const Container = styled(Box)(
  css({
    marginTop: `${headerHeight}px`,
    color: ThemeColors.Active2,
    height: `${TopContentStyle.containerHeight}px`,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    border: '1px solid #F2F6FA',
    boxShadow: '0 2px 1px 0 rgba(206,215,226,0.75)',
  })
);

const MdmModule = styled('span')(
  css({
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#1E2432',
  })
);

const ProductDivider = styled('div')(
  css({
    width: '1px',
    height: '48px',
    border: '1px solid #E4E4E4',
    boxSizing: 'border-box',
    marginLeft: '10px',
  })
);

const TopContent: React.VFC = () => {
  return (
    <Box position="fixed" top={0} width="100%" zIndex={2}>
      <Container>
        <Box mx={3}>
          <MdmLogo />
        </Box>
        <Box mx={0} style={{ width: '72px' }}>
          <TaniumTM />
          <MdmModule>MDM</MdmModule>
        </Box>
        <Box ml={ThemeSpace.minor[1]} mr={ThemeSpace.minor[1]} className="hideOnSmallScreen">
          <ProductDivider />
        </Box>
        <div className="hideOnSmallScreen">
          <ProductInfo />
        </div>
      </Container>
    </Box>
  );
};

export default TopContent;
