/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import DE from './locales/de-DE/translation.json';
import EN from './locales/en-US/translation.json';
import ES from './locales/es-LA/translation.json';
import FR from './locales/fr-FR/translation.json';
import JA from './locales/ja-JP/translation.json';
import KO from './locales/ko-KR/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: true,
    ns: ['translation'],
    defaultNS: 'translation',
    resources: {
      'de-DE': {
        translation: DE,
      },
      'en-US': {
        translation: EN,
      },
      'es-LA': {
        translation: ES,
      },
      'fr-FR': {
        translation: FR,
      },
      'ja-JP': {
        translation: JA,
      },
      'ko-KR': {
        translation: KO,
      },
      // When we initially get translations back, add them here
    },
    interpolation: {
      escapeValue: false,
    },
    initImmediate: true,
    parseMissingKeyHandler: (key: string) => key,
  });

export default i18n;
