import React, { useMemo } from 'react';
import { createBrowserHistory } from 'history';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import AppWrapper from './AppWrapper';
import AppContainer from './AppContainer';

const App: React.VFC = () => {
  const history = useMemo(() => createBrowserHistory(), []);

  const renderApp = () => {
    return <AppContainer />;
  };
  return (
    <I18nextProvider i18n={i18n}>
      <AppWrapper history={history}>{renderApp()}</AppWrapper>
    </I18nextProvider>
  );
};

export default App;
