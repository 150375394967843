import React from 'react';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';

interface Props {
  username: string | undefined;
}

const SmallText = styled('span')(
  css({
    fontSize: 12,
  })
);

const CurrentUser: React.VFC<Props> = ({ username }) => {
  return <SmallText>{username}</SmallText>;
};

export default CurrentUser;
