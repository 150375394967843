import React, { useCallback, useState } from 'react';
import { ControlGroup, ControlGroupItem } from '@tanium/react-control-group';
import { TextInput } from '@tanium/react-text-input';
import { Spinner } from '@tanium/react-spinner';
import { Button } from '@tanium/react-button';
import styled from '@tanium/react-emotion-9';
import { useTranslation } from 'react-i18next';
import { css } from '@tanium/style-system';
import { StateCriticalIcon } from '@tanium/react-graphics';
import { Box } from '@tanium/react-box';
import { validateEmail, getDomain, getAuthMetadata, AuthMetadataResponse } from '../../utils/Helper';
import { setSessionSettings, signIn, AuthSessionSettings } from '../../utils/Auth';
import Notification from '../Notification/Notification';

const EnrollDescription = styled('span')(
  css({
    marginTop: '30px',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#292D38',
    textAlign: 'center',
  })
);

const InvalidEmailMessage = styled('div')(
  css({
    display: 'flex',
    margin: 'auto',
    marginTop: '10px',
    fontSize: '12px',
    color: '#E6444E',
  })
);

const SubmitButton = styled(Button)(
  css({
    display: 'flex',
    alignItems: 'center',
  })
);

const EmailSignIn: React.VFC = () => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const handleEmailChanged = useCallback(
    ({ currentTarget: { value } }) => {
      setEmail(value as string);
    },
    [setEmail]
  );

  const handleEmailOnBlur = useCallback(
    ({ currentTarget: { value } }) => {
      setEmailValid(validateEmail(value as string));
    },
    [setEmailValid]
  );

  const handleDismissErrorNotification = useCallback(() => {
    setErrorMessage('');
  }, [setErrorMessage]);

  const handleSubmit = useCallback(() => {
    void (async () => {
      if (validateEmail(email)) {
        setEmailValid(true);
        setLoading(true);

        let response: AuthMetadataResponse;
        try {
          response = await getAuthMetadata(email);
          if (response.error) {
            setErrorMessage(response.error);
            return;
          }
          if (!response.data) {
            setErrorMessage(t('NO_DATA'));
            return;
          }
        } catch (error) {
          console.log(`get auth metadata error ${JSON.stringify(error)}`);
          setErrorMessage(t('UNKNOWN_ERROR'));
          return;
        } finally {
          setLoading(false);
        }
        const domain = getDomain(email);
        const settings: AuthSessionSettings = {
          clientId: response.data.clientId,
          host: response.data.host,
          poolId: response.data.poolId,
          region: response.data.region,
          domain,
          deviceEnrollmentUrl: response.data.deviceEnrollmentUrl,
          preferredIdP: response.data.preferredIdP,
        };
        setSessionSettings(settings);
        await signIn();
      } else {
        setEmailValid(false);
      }
    })();
  }, [email, setEmailValid, setLoading, setErrorMessage, t]);

  const handleEnterPressed = useCallback(
    ({ key }) => {
      if (key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <>
      <EnrollDescription>{t('AUTHENTICATE_TO_DOWNLOAD')}</EnrollDescription>
      <Box maxWidth="500px" width="100%" alignSelf="center" marginTop="30px">
        <ControlGroup>
          <ControlGroupItem grow={1}>
            <TextInput
              style={{ maxHeight: '1em' }}
              type={'email'}
              placeholder={t('EMAIL_PLACEHOLDER')}
              value={email}
              onChange={handleEmailChanged}
              onBlur={handleEmailOnBlur}
              onKeyPress={handleEnterPressed}
            />
          </ControlGroupItem>
          <SubmitButton disabled={loading} onClick={handleSubmit}>
            {loading ? (
              <div style={{ margin: 'auto', display: 'flex' }}>
                <Spinner size={16} fill={false} />
                <label style={{ marginLeft: '4px' }}>Submitting...</label>
              </div>
            ) : (
              <label style={{ margin: 'auto', cursor: 'pointer' }}>{t('AUTHENTICATE')}</label>
            )}
          </SubmitButton>
        </ControlGroup>
        {emailValid ? null : (
          <InvalidEmailMessage>
            <StateCriticalIcon height={14} />
            <label style={{ marginLeft: '4px' }}>{t('ENTER_EMAIL')}</label>
          </InvalidEmailMessage>
        )}
      </Box>
      <Notification type="error" role="status" onDismiss={handleDismissErrorNotification} message={errorMessage} />
    </>
  );
};

export default EmailSignIn;
