import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import i18 from '../i18n';

const SESSION_SETTINGS_KEY = 'enrollAuthSessionSettings';

const DEVICE_INFO_KEY = 'deviceInfo';

export interface AuthSessionSettings {
  domain?: string;
  region: string;
  poolId: string;
  clientId: string;
  host: string;
  deviceEnrollmentUrl: string;
  preferredIdP?: string;
}

export interface AuthSession {
  username: string;
  accessToken: string;
  canEnroll: boolean;
  error: string | null;
}

export const getSessionSettings = (): AuthSessionSettings | null => {
  const settingsRaw: string | null = sessionStorage.getItem(SESSION_SETTINGS_KEY);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const settings: AuthSessionSettings | null = settingsRaw ? JSON.parse(settingsRaw) : null;
  return settings;
};

export const setSessionSettings = (settings: AuthSessionSettings): void => {
  sessionStorage.setItem(SESSION_SETTINGS_KEY, JSON.stringify(settings));
};

export const setDeviceInfo = (deviceInfo: string): void => {
  sessionStorage.setItem(DEVICE_INFO_KEY, deviceInfo);
};

export const getDeviceInfo = (): string | null => {
  return sessionStorage.getItem(DEVICE_INFO_KEY);
};

export const getCurrentSession = async (): Promise<AuthSession | null> => {
  const settings: AuthSessionSettings | null = getSessionSettings();
  if (!settings) {
    return null;
  }
  configure(settings);
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const userSession = user.getSignInUserSession();
    if (!userSession?.isValid()) {
      return null;
    }

    const idPayload = userSession.getIdToken()?.decodePayload() ?? '';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const userName: string = idPayload.email ?? user.getUsername();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const canEnroll: boolean = (idPayload['custom:can_enroll'] ?? '').toUpperCase() === 'TRUE';

    const jwtToken = userSession.getAccessToken().getJwtToken();
    return { username: userName, accessToken: jwtToken, canEnroll, error: null };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return { error: i18.t('AUTHENTICATION_FAILED') } as AuthSession;
  }
};

export const configure = (authSettings: AuthSessionSettings): void => {
  const settings = {
    region: 'XX-XXXX-X',
    userPoolId: 'XX-XXXX-X_XXXXXXXXX',
    userPoolWebClientId: 'XXXXXXXXXXXXXXXXXXXXXXXXXX',
    mandatorySignIn: false,
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: 365,
      sameSite: 'lax',
      secure: false,
    },
    oauth: {
      domain: 'localhost',
      // scopes: ['phone', 'profile', 'email', 'openid'],
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: `${window.location.origin}`,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  };

  settings.region = authSettings.region;
  settings.userPoolId = authSettings.poolId;
  settings.userPoolWebClientId = authSettings.clientId;
  settings.oauth.domain = authSettings.host;

  Amplify.configure({
    Auth: settings,
  });
};

export const signOutSession = async (): Promise<void> => {
  const settings = getSessionSettings();
  if (settings) {
    configure(settings);
    sessionStorage.removeItem(SESSION_SETTINGS_KEY);
    await Auth.signOut();
  }
};

export const signIn = async (): Promise<void> => {
  const settings = getSessionSettings();
  if (settings) {
    configure(settings);
    if (settings.preferredIdP) {
      await Auth.federatedSignIn({
        customProvider: settings.preferredIdP,
      });
    } else {
      await Auth.federatedSignIn();
    }
  }
};
