import React from 'react';
import { Link } from 'react-router-dom';
import TaniumLogo from '../../static/tanium_logo.svg';

const DEFAULT_LOGO_HEIGHT = 24;

const Logo: React.VFC = () => {
  return (
    <Link to="/">
      <img src={TaniumLogo} alt="Tanium logo" height={DEFAULT_LOGO_HEIGHT} />
    </Link>
  );
};

export default Logo;
