import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Shell from '../Shell/Shell';
import { getCurrentSession, AuthSession, setDeviceInfo } from '../../utils/Auth';
import { useTranslation } from 'react-i18next';
import Notification from '../Notification/Notification';

const AppContainer: React.VFC = () => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<AuthSession | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const handleDismissErrorNotification = useCallback(() => {
    setErrorMessage('');
  }, [setErrorMessage]);

  // read the 'deviceInfo' query parameter
  // this is the encrypted deviceInfo containing the serial number of the device and a one time password
  // this is set by device enrollment svc in the case of a user-authenticated DEP enrollment flow
  const deviceInfo = new URLSearchParams(useLocation().search).get('deviceInfo');
  if (deviceInfo) {
    setDeviceInfo(deviceInfo);
  }

  useEffect(() => {
    async function getAuthState() {
      const authSession: AuthSession | null = await getCurrentSession();
      if (authSession?.error) {
        setErrorMessage(authSession.error);
      }
      setSession(authSession);
      setLoading(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getAuthState();
  }, [setSession, t]);

  return (
    <>
      <Shell session={session} loading={loading} />
      <Notification type="error" role="status" onDismiss={handleDismissErrorNotification} message={errorMessage} />
    </>
  );
};

export default AppContainer;
