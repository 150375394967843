/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, { AxiosRequestConfig } from 'axios';
import { AuthSessionSettings, getSessionSettings, getDeviceInfo } from './Auth';
import i18 from '../i18n';

// Exporting for unit test only. Having it inline where it's used doesn't do it for coverage
export const axiosConfigNoThrow = {
  validateStatus: () => true,
} as AxiosRequestConfig;

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getDomain = (email: string): string => {
  if (!validateEmail(email) || email === '') {
    return '';
  }
  return email.split('@')[1].toLowerCase();
};

export interface DiscoveryMetadataResponse {
  error: string | null;
  deviceEnrollmentUrl: string;
}

// It's expected a lambda will be invoked at /discovery to handle the request
export const getDiscoveryData = async (email: string): Promise<DiscoveryMetadataResponse> => {
  const response = {} as DiscoveryMetadataResponse;
  let { hostname } = window.location;
  if (hostname !== 'localhost') {
    const domain = getDomain(email);

    const resp = await axios.get(`${window.location.origin}/discovery?domain=${domain}`, axiosConfigNoThrow);

    if (resp.status === 200 && resp.data?.deviceEnrollmentUrl) {
      response.deviceEnrollmentUrl = resp.data.deviceEnrollmentUrl;
    } else if (resp.status === 403) {
      response.error = i18.t('NO_DATA');
    } else {
      console.log(`data discovery error ${JSON.stringify(resp)}`);
      response.error = i18.t('UNKNOWN_ERROR');
    }
  } else {
    let apiPort = '8080';
    if (process.env.REACT_APP_API_PORT) {
      apiPort = process.env.REACT_APP_API_PORT;
    }
    hostname += `:${apiPort}`;
    const { protocol } = window.location;
    response.deviceEnrollmentUrl = `${protocol}//${hostname}`;
  }

  return response;
};

interface AuthMetadata {
  host: string;
  poolId: string;
  clientId: string;
  region: string;
  deviceEnrollmentUrl: string;
  preferredIdP?: string;
}

export interface AuthMetadataResponse {
  error: string | null;
  data: AuthMetadata | null;
  status: number | null;
}

export const getAuthMetadata = async (email: string): Promise<AuthMetadataResponse> => {
  const response: AuthMetadataResponse = { data: null, error: null, status: 0 };
  const discoveryData = await getDiscoveryData(email);

  if (discoveryData.error) {
    console.log(discoveryData.error);
    response.error = discoveryData.error;
    return response;
  }

  try {
    const { data } = await axios.get(`${discoveryData.deviceEnrollmentUrl}/authmetadata?email=${email}`);
    response.data = data as AuthMetadata;
    response.data.deviceEnrollmentUrl = discoveryData.deviceEnrollmentUrl;
  } catch (err: any) {
    console.log(` auth  err: ${JSON.stringify(err)}`);

    if (err.response?.status === 412) {
      response.error = i18.t('NOT_CONFIGURED');
    } else {
      response.error = i18.t('UNKNOWN_ERROR');
    }
  }

  return response;
};

export interface DownloadProfileResponse {
  success: boolean;
  error: string | null;
}

export const getPlatform = (): string | null => {
  const ua: string = navigator.appVersion.toLowerCase();
  if (ua.includes('macintosh') || ua.includes('ipad') || ua.includes('iphone')) {
    return 'apple';
  }
  if (ua.includes('windows')) {
    return 'windows';
  }
  return null;
};

export const downloadProfile = async (token: string): Promise<DownloadProfileResponse> => {
  const response: DownloadProfileResponse = { success: false, error: null };
  try {
    const settings = getSessionSettings() as AuthSessionSettings;
    const baseUrl = settings.deviceEnrollmentUrl;
    const platform: string | null = getPlatform();
    if (!platform) {
      response.success = false;
      response.error = i18.t('UNKNOWN_PLATFORM');
      return response;
    }
    // retrieve the deviceInfo from the session
    const deviceInfo = getDeviceInfo();
    // create the form that will call device enrollment service to retrieve an enrollment profile
    const form = document.createElement('form');
    document.body.appendChild(form);
    form.setAttribute('method', 'post');
    form.setAttribute('action', `${baseUrl}/apple/enroll`);
    const authorizationInput = document.createElement('input');
    authorizationInput.name = 'Authorization';
    authorizationInput.type = 'hidden';
    authorizationInput.value = `Bearer ${token}`;
    form.appendChild(authorizationInput);
    if (deviceInfo) {
      // it will only be present in a user-authenticated DEP enrollment flow
      // set the device info in the body so that device enrollment svc can process it
      const deviceInfoInput = document.createElement('input');
      deviceInfoInput.name = 'x-deviceinfo';
      deviceInfoInput.type = 'hidden';
      deviceInfoInput.value = deviceInfo;
      form.appendChild(deviceInfoInput);
    }
    form.submit();
    document.body.removeChild(form);
    response.success = true;
  } catch (err: any) /* istanbul ignore next */ {
    console.log(err);
    response.success = false;
    response.error = i18.t('UNKNOWN_ERROR');
    if (typeof err.response !== 'undefined') {
      const data = new Blob([err.response.data]);
      const errData = JSON.parse(await data.text());
      console.log(errData.message);
    }
  }
  return response;
};
