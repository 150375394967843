import React, { useCallback, useState } from 'react';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { Button } from '@tanium/react-button';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@tanium/react-spinner';
import Notification from '../Notification/Notification';
import { downloadProfile, DownloadProfileResponse } from '../../utils/Helper';
import { getDeviceInfo } from '../../utils/Auth';

const DownloadDescription = styled('span')(
  css({
    marginTop: '30px',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#292D38',
    textAlign: 'center',
  })
);

const DownloadButton = styled(Button)(
  css({
    margin: 'auto',
    marginTop: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

interface Props {
  token: string;
}

const Download: React.VFC<Props> = ({ token }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const handleDownload = useCallback(() => {
    void (async () => {
      setLoading(true);
      try {
        const response: DownloadProfileResponse = await downloadProfile(token);
        if (response.error) {
          setErrorMessage(response.error);
        }
      } catch (error) {
        console.log(`download profile error ${JSON.stringify(error)}`);
        setErrorMessage(t('UNKNOWN_ERROR'));
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoading, setErrorMessage, token, t]);

  const handleDismissErrorNotification = useCallback(() => {
    setErrorMessage('');
  }, [setErrorMessage]);

  const deviceInfo = getDeviceInfo();
  let buttonLabel = t('DOWNLOAD_PROFILE');
  let loadingButtonLabel = t('DOWNLOADING_PROFILE');
  if (deviceInfo) {
    buttonLabel = t('INSTALL_PROFILE');
    loadingButtonLabel = t('INSTALLING_PROFILE');
  }

  return (
    <>
      <DownloadDescription>{t('PROFILE_READY')}</DownloadDescription>
      <DownloadButton disabled={loading} onClick={handleDownload}>
        {loading ? (
          <div style={{ margin: 'auto', display: 'flex' }}>
            <Spinner size={16} fill={false} />
            <label style={{ marginLeft: '4px' }}>{loadingButtonLabel}</label>
          </div>
        ) : (
          <label style={{ margin: 'auto', cursor: 'pointer' }}>{buttonLabel}</label>
        )}
      </DownloadButton>
      <Notification type="error" role="status" onDismiss={handleDismissErrorNotification} message={errorMessage} />
    </>
  );
};

export default Download;
