import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { useTranslation } from 'react-i18next';

const CannotEnrollDescription = styled('span')(
  css({
    marginTop: '30px',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#292D38',
    textAlign: 'center',
  })
);

const CannotEnroll: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <>
      <CannotEnrollDescription>{t('CANNOT_ENROLL')}</CannotEnrollDescription>
    </>
  );
};

export default CannotEnroll;
