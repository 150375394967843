import React from 'react';
import { TaniumWordmark } from '@tanium/react-graphics';

const DEFAULT_LOGO_HEIGHT = 8;

const TaniumTM: React.VFC = () => {
  return <TaniumWordmark height={DEFAULT_LOGO_HEIGHT} />;
};

export default TaniumTM;
